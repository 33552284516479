import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------
const Loadable = (Component) =>
  // eslint-disable-next-line func-names
  function (props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "error-occured", element: <ErrorOccured /> },
        {
          path: "",
          children: [
            {
              path: "",
              element: <TakeTest />,
            },
            {
              path: ":resultId/:testId",
              element: <TakeTest />,
            },
            {
              path: ":resultId/:testId/accept",
              element: <AcceptTest />,
            },
          ],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "error-occured", element: <ErrorOccured /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
const TakeTest = Loadable(lazy(() => import("../pages/TakeTest")));
const AcceptTest = Loadable(lazy(() => import("../pages/AcceptTest")));
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
