import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box
      component="img"
      src="/logo/logo-small.png"
      sx={{ width: 40, height: 40, ...sx }}
    />
  );
  if (disabledLink) {
    return logo;
  }
  return <RouterLink to="/">{logo}</RouterLink>;
}
