import axios from './axios';

const setSession = (accessToken, userType) => {
  if (accessToken && userType) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('userType', userType);
    setHeader();
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userType');
    axios.defaults.headers.common.Authorization = `Bearer `;
  }
};
const setHeader = () => {
  const accessToken = window.localStorage.getItem('accessToken') ? window.localStorage.getItem('accessToken') : "";
  const userType = window.localStorage.getItem('userType') ? window.localStorage.getItem('userType') : "";
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken} ${userType}`;
}

export { setSession, setHeader };
