function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_API = "https://api.wealthbridge.com.ng/app/";

export const API_PATH = {
  root: ROOTS_API,
  assessment: path(ROOTS_API, "assessment"),
};
export const PUSHER_KEY = "4431c079bc03156ab058";
