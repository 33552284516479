import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    const auth = response.config.headers.Authorization;
    if (response.data.status && (auth && auth !== "Bearer  ") && (response.data.status === "userid_expired" || response.data.status === "userid_error" || response.data.status === 'permission_denied')) {
      window.location.reload();
    }
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || new Error("Something went wrong! Please try again later."))
);

export default axiosInstance;
