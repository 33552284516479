import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { API_PATH } from "../utils/apis";
import { setSession, setHeader } from "../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isDomainOkay: false,
  isVerifyEmail: false,
  user: null,
  usertype: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isDomainOkay, user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isAuthenticated,
      isVerifyEmail: false,
      isInitialized: true,
      isDomainOkay,
      user,
      usertype,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      user,
      usertype,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      user,
      usertype,
    };
  },
  LOGOUT: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      user,
      usertype,
    };
  },
  SETPASSWORD: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isAuthenticated: false,
      isDomainOkay: true,
      isVerifyEmail: true,
      user,
      usertype,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    const usertype = Object.prototype.hasOwnProperty.call(user, "user")
      ? parseInt(user.user.usertype, 10)
      : null;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      isDomainOkay: true,
      user,
      usertype,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      setHeader();
      let isAuthenticated = false;
      let isDomainOkay = false;
      let user = {};
      try {
        const subdomain = window.location.host.split(".")[1]
          ? window.location.host.split(".")[0]
          : false;
        if (subdomain) {
          const response = await axios.post(API_PATH.profile, {
            get: "",
            domain: subdomain,
          });
          const { status, data } = response.data;
          user = data;
          if (status === "success" && user.school.domain === subdomain) {
            isDomainOkay = true;
            isAuthenticated = Boolean(user.user.uid);
          }
          if (
            status === "userid_expired" ||
            status === "userid_error" ||
            status === "invalid_profile"
          ) {
            isDomainOkay = true;
            isAuthenticated = false;
            setSession(null);
          }
        }
      } catch (err) {
        // console.error(err);
      }
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated,
          isDomainOkay,
          user,
        },
      });
    };
    initialize();
  }, []);

  const getProfile = async () => {
    let isAuthenticated = false;
    let isDomainOkay = true;
    let user = {};
    try {
      const response = await axios.post(API_PATH.profile, { get: "" });
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        isDomainOkay = true;
        isAuthenticated = Boolean(user.user.uid);
      }
      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isDomainOkay = true;
        isAuthenticated = false;
        setSession(null);
      }
    } catch (err) {
      // console.error(err);
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isDomainOkay,
        user,
      },
    });
  };
  const getTest = async (testId, resultId) => {
    if (!testId || !resultId) {
      throw new Error(
        "The link you entered is invalid! Please check the link properly and try again later."
      );
    }
    const response = await axios.post(API_PATH.assessment, {
      testId,
      resultId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "not_found") {
      throw new Error(
        "The link you entered is invalid! Please check the link properly and try again later."
      );
    }
    throw new Error("An error has occured! Please check back later.");
  };
  const acceptTest = async (testId, resultId) => {
    if (!testId || !resultId) {
      throw new Error(
        "The link you entered is invalid! Please check the link properly and try again later."
      );
    }
    const response = await axios.post(API_PATH.assessment, {
      accept: testId,
      resultId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "not_found") {
      throw new Error(
        "The link you entered is invalid! Please check the link properly and try again later."
      );
    }
    throw new Error("An error has occured! Please check back later.");
  };
  const saveTestAnswers = async (testId, resultId, answers, isFinal) => {
    if (!testId || !resultId) {
      throw new Error("An error occured! Please try again later.");
    }
    const response = await axios.post(API_PATH.assessment, {
      save_answer: testId,
      resultId,
      isFinal,
      answers: JSON.stringify(answers),
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "not_found") {
      throw new Error("An error occured! Please try again later.");
    }
    throw new Error(
      "Something went wrong while fetching saving your answers! Please try again later."
    );
  };
  const startTest = async (testId, resultId) => {
    if (!testId || !resultId) {
      throw new Error("An error occured! Please try again later.");
    }
    const response = await axios.post(API_PATH.assessment, {
      start: testId,
      resultId,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    if (status === "not_found") {
      throw new Error("An error occured! Please try again later.");
    }
    throw new Error(
      "Something went wrong while fetching saving your answers! Please try again later."
    );
  };
  const suspendTest = async (suspend, suspension) => {
    if (!suspend) {
      throw new Error("An error occured! Please try again later.");
    }
    await axios.post(API_PATH.assessment, {
      suspend,
      suspension,
    });
  };
  const fetchZegoToken = async (testId, resultId) => {
    if (!testId || !resultId) {
      throw new Error("An error occured! Please try again later.");
    }
    const response = await axios.post(API_PATH.assessment, {
      init_chat: testId,
      resultId,
    });
    const { status, token } = response.data;
    if (status === "success") {
      return token;
    }
    if (status === "not_found") {
      throw new Error("An error occured! Please try again later.");
    }
    throw new Error(
      "Something went wrong while connecting you! Please try again later."
    );
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        method: "jwt",
        getProfile,
        suspendTest,
        acceptTest,
        getTest,
        saveTestAnswers,
        startTest,
        fetchZegoToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
