export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            height: '1em'
          },
          '& .MuiInputBase-root': {
            lineHeight: '1em'
          }
          ,
          '& .MuiFormLabel-root':{
            lineHeight: '1em'
          }
        }
      }
    }
  };
}